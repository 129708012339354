import { ReactNode } from 'react';

import { ModalQueueItem, ServiceWrapperMethods } from 'services/modalServiceFactory.types';
import ModalProps from 'components/core/Modal/Modal.types';

/**
 * A client service for global modal management.
 *
 * It acts as the single source of truth for all modals state in the app.
 * It provides method of managing backdrop and modal boxes in a way that
 * allows modal queueing, where one modal opens another in a smooth transition.
 *
 * @returns public client API for modal management
 */
export default class ModalServiceFactory {
  private defaultModalProps: ModalProps = {};

  /**
   * Stores currently open modal and, if required, next modal to be opened
   * after the first one closes.
   */
  private modalQueue: ModalQueueItem[] = [];

  /**
   * Since the whole service won't work without initial call to
   * bindServiceWrapperMethods made by ModalServiceWrapper, we assume
   * that the methods object will always be defined and initialized.
   */
  private serviceWrapperMethods: ServiceWrapperMethods = {} as ServiceWrapperMethods;

  constructor(defaultModalProps: ModalProps = {}) {
    this.defaultModalProps = defaultModalProps;
  }

  /**
   * Links modalService instance with top-level ModalServiceWrapper component
   * in order to properly manage backdrop and active modal states through
   * its local state.
   */
  public bindServiceWrapperMethods(methods: ServiceWrapperMethods): void {
    this.serviceWrapperMethods = methods;
  }

  /**
   * Toggles DOM's body tag 'blockScroll' class that prevents user from
   * being able to scroll the application page.
   */
  // eslint-disable-next-line class-methods-use-this
  private setBodyScrollLock = (isScrollLocked: boolean) => {
    const scrollLockClassName = 'blockScroll';
    const { classList } = document.body;
    if (isScrollLocked) {
      classList.add(scrollLockClassName);
    } else {
      classList.remove(scrollLockClassName);
    }
  };

  /**
   * Opens next modal in modal queue.
   */
  private openNextModal = () => {
    const { ModalContent, modalProps } = this.modalQueue[0];
    this.serviceWrapperMethods.setModalProps(modalProps);
    this.serviceWrapperMethods.setModalContent(ModalContent);
    setTimeout(() => {
      this.serviceWrapperMethods.setIsModalOpen(true);
    }, 0);
  };

  /**
   * Closes currently open modal. If there is no new modal
   * to be immediately opened next, also removes backdrop.
   */
  public closeCurrentModal = (modalOnClosed?: ModalProps['onClosed']): void => {
    this.serviceWrapperMethods.setModalOnClosed(() => () => {
      this.serviceWrapperMethods.setModalProps({});
      this.serviceWrapperMethods.setModalContent(null);
      this.serviceWrapperMethods.setModalOnClosed(undefined);
      if (modalOnClosed) {
        modalOnClosed();
      }
    });
    this.modalQueue.shift();
    this.serviceWrapperMethods.setIsModalOpen(false);
    if (!this.modalQueue.length) {
      this.serviceWrapperMethods.setIsBackdropVisible(false);
      this.setBodyScrollLock(false);
    }
  };

  /**
   * Opens new modal. If it's the first modal to be opened in a given queue,
   * also shows backdrop. If a modal is already open, closes it first and
   * then opens the new one.
   */
  public openModal = (modalProps: ModalProps, ModalContent: ReactNode): void => {
    const isModalAlreadyOpen = this.modalQueue.length >= 1;
    this.modalQueue.push({
      ModalContent,
      modalProps: {
        ...this.defaultModalProps,
        ...modalProps,
      },
    });
    if (isModalAlreadyOpen) {
      this.closeCurrentModal(this.openNextModal);
    } else {
      this.serviceWrapperMethods.setIsBackdropVisible(true);
      this.setBodyScrollLock(true);
      this.openNextModal();
    }
  };
}
