import { format } from 'date-fns';
import React, { FC, useCallback } from 'react';

import { ApiTransactionSummary } from 'store/api/api.types';
import { TransactionsListProps } from 'components/dedicated/organization/TransactionsList/TransactionsList.types';
import PageHeader from 'components/core/PageHeader/PageHeader';
import SlideoutTransactionDetails from 'components/dedicated/SlideoutTransactionDetails/SlideoutTransactionDetails';
import TransactionItem from 'components/dedicated/TransactionItem/TransactionItem';
import slideoutService from 'services/slideoutService';

const TransactionsList: FC<TransactionsListProps> = ({
  organizationId,
  transactionSummaries,
  shouldSectionByMonths,
}) => {
  const handleOnClick = useCallback(
    (transactionSummary: ApiTransactionSummary) => {
      slideoutService.openModal(
        {},
        <SlideoutTransactionDetails
          organizationId={organizationId}
          transactionSummary={transactionSummary}
        />,
      );
    },
    [organizationId],
  );

  const getMonthYear = (transaction: ApiTransactionSummary) => {
    const date = new Date(transaction.attributes.createdAt);
    return format(date, 'MMMM yyyy');
  };

  let lastMonthYear = '';

  return (
    <div>
      {transactionSummaries.map((transaction, index) => {
        const currentMonthYear = getMonthYear(transaction);
        const showHeader = shouldSectionByMonths && currentMonthYear !== lastMonthYear;
        lastMonthYear = currentMonthYear;

        return (
          <div key={transaction.id}>
            {showHeader && (
              <PageHeader
                marginTop={index === 0 ? 0 : 8}
                title={currentMonthYear}
                variant='sectionHeaderMedium'
              />
            )}
            <TransactionItem onClick={handleOnClick} transactionSummary={transaction} />
          </div>
        );
      })}
    </div>
  );
};

export default TransactionsList;
