import { OptionProps, components } from 'react-select';
import React, { FC } from 'react';
import cx from 'classnames';

import { SearchFieldOptionProps } from 'components/dedicated/SearchField/SearchField.types';
import Box from 'components/core/Box/Box';
import Text from 'components/core/Text/Text';

import styles from '../SearchField.module.scss';

export const SearchFieldOption: FC<SearchFieldOptionProps> = ({
  data: suggestion,
  selectProps,
  ...props
}) => {
  const { id, title, subtitle, isHiddenOption } = suggestion;
  const { inputValue, isLoading } = selectProps;
  const searchTerm = inputValue;
  const { isFocused } = props;
  const index = title.toLowerCase().indexOf(searchTerm.toLowerCase());

  const before = title.substring(0, index);
  const after = title.substring(index + searchTerm.length);
  const match = title.substring(index, index + searchTerm.length);

  if (isLoading || isHiddenOption) {
    return null;
  }

  return (
    <components.Option {...(props as OptionProps)}>
      <Box
        key={id}
        alignItems='flex-start'
        className={cx(styles.suggestionResultItem, isFocused && styles.suggestionResultItemFocused)}
        display='flex'
        flexDirection='row'
        gap={3}
      >
        <Box className={styles.suggestionIcon}>{suggestion.icon}</Box>
        <Box alignItems='flex-start' display='flex' flexDirection='column' gap={1.5}>
          {index >= 0 ? (
            <Text display='flex'>
              {before}
              <Text className={styles.matchHighlightedPortion}>{match}</Text>
              {after}
            </Text>
          ) : (
            <Text display='flex'>{title}</Text>
          )}
          <Text color='charcoalBase' variant='bodyCopySmall'>
            {subtitle}
          </Text>
        </Box>
      </Box>
    </components.Option>
  );
};
